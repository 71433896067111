import React, { Component } from "react"
import { withFirebase } from "../Firebase"
import { Modal } from "semantic-ui-react"
import * as ROUTES from "../../constants/routes"

const INITIAL_STATE = {
  email: "",
  error: null,
}

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email } = this.state

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ sent: true })
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error, sent } = this.state

    const isInvalid = email === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-field">
          <label htmlFor="email">Email address</label>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </div>
        <div class="input-field">
          <button disabled={isInvalid} type="submit" className="btn">
            <span>Reset My Password</span>
          </button>
        </div>
        {error && <p>{error.message}</p>}

        {sent && (
          <Modal open={true} size="mini" className="modal_password">
            <p className="body--m">Please check your email for a reset link.</p>
            <a href={ROUTES.SIGN_IN} class="btn red">
              <span>Close</span>
            </a>
          </Modal>
        )}
      </form>
    )
  }
}

export default withFirebase(PasswordForgetForm)
