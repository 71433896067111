import React from "react"
import Layout from "../components/layout"
import PasswordForgetForm from "../components/PasswordForget"

export default () => (
  <Layout activePage="pw_forget">
    <section className="page_login">
      <div className="wrapper">
        <div className="row centered">
          <div className="col col-4">
            <h1 className="heading--l thin">Forgot Password</h1>
            <PasswordForgetForm />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
